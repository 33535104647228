import * as React from 'react';
import { Link, matchPath, NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import suitLogo from '../images/suitlogo.svg'
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import Footer from './Footer';
import LanguagePicker, { languages } from './LanguagePicker';
import { useCookies } from 'react-cookie';

const navBarLink = (selected: boolean) => `text-blue-500 text-5xl hover:animate-pulse hover:text-red-500 selection:bg-none ${selected && 'text-red-500'}`;

const NavigationBar = () => {

    let menuRef = React.createRef<HTMLDivElement>();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [cookies, setCookie] = useCookies(['lang']);
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!languages.find((lang) => lang.path.replaceAll('/', '') === params.lang)) {
            navigate(cookies.lang || '/en')
        }
        if (languages.find((lang) => lang.path.replaceAll('/', '') === params.lang) && cookies.lang !== `/${params.lang}`) {
            setCookie('lang', `/${params.lang}`);
            console.log('cookie set');
        }
    }, [params.lang])

    const handleOnClickMenu = () => {
        menuRef.current?.toggleAttribute('active');
        setMenuOpen(!menuRef.current!.style.maxHeight);
        if (menuRef.current!.style.maxHeight) {
            menuRef.current!.style.maxHeight = '';
        } else {
            menuRef.current!.style.maxHeight = (menuRef.current?.scrollHeight || 0) + 10 + "px";
        }
    }

    const onClickNavLink = () => {
        menuRef.current?.toggleAttribute('active');
        menuRef.current!.style.maxHeight = '';
        setMenuOpen(false);
    }

    return (<div dir={params.lang === 'he' ? 'rtl' : 'ltr'} className='flex-1 overflow-hidden'>
        <div>
            <div className='py-4 px-4 xl:my-16 xl:mx-16 bg-white flex flex-row items-center'>
                <Link to='/'>
                    <div dir='ltr' className='flex flex-row items-center space-x-1 hover:animate-pulse'>
                        <div style={{ backgroundImage: `url(${suitLogo})` }}
                            className='ml-2 xl:mx-0 h-10 w-10 xl:h-20 xl:w-20 rounded-lg bg-contain'
                        />
                        <div className='text-4xl py-2 xl:text-7xl font-semibold'>
                            PumpSuit
                        </div>
                    </div>
                </Link>
                <div className='hidden xl:flex flex-1'>
                    <nav className='space-x-3 font-thin flex-row flex-1 flex justify-center items-center rtl:space-x-reverse'>
                        <NavLink className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/`) !== null)} to={`/${params.lang}`}>{params.lang === 'he' ? 'בית' : 'Home'}</NavLink>
                        <div className='text-6xl text-gray-400'>·</div>
                        <NavLink className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/company/`) !== null)} to='company'>{params.lang === 'he' ? 'חברה' : 'Company'}</NavLink>
                        <div className='text-6xl text-gray-400'>·</div>
                        <NavLink className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/platform/`) !== null)} to='platform'>{params.lang === 'he' ? 'פלטפורמה' : 'Platform'}</NavLink>
                        <div className='text-6xl text-gray-400'>·</div>
                        <NavLink className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/contact/`) !== null)} to='contact'>{params.lang === 'he' ? 'צרו קשר' : 'Contact'}</NavLink>
                        <div className='text-6xl text-gray-400'>·</div>
                        <LanguagePicker />
                    </nav>
                </div>
                <div className='flex flex-1 justify-end xl:hidden'>
                    <button onClick={handleOnClickMenu.bind(this)}>
                        {menuOpen ? <XMarkIcon className='w-10 h-10' /> : <Bars3Icon className='w-10 h-10' />}
                    </button>
                </div>
            </div>
        </div>
        <div ref={menuRef} className='overflow-hidden max-h-0 transition-[max-height] duration-300 ease-in-out xl:hidden'>
            <nav className='font-thin block text-center py-16'>
                <NavLink onClick={onClickNavLink} className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/`) !== null)} to={`/${params.lang}`}>{params.lang === 'he' ? 'בית' : 'Home'}</NavLink>
                <div className='text-6xl text-gray-400'>·</div>
                <NavLink onClick={onClickNavLink} className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/company/`) !== null)} to='company'>{params.lang === 'he' ? 'חברה' : 'Company'}</NavLink>
                <div className='text-6xl text-gray-400'>·</div>
                <NavLink onClick={onClickNavLink} className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/platform/`) !== null)} to='platform'>{params.lang === 'he' ? 'פלטפורמה' : 'Platform'}</NavLink>
                <div className='text-6xl text-gray-400'>·</div>
                <NavLink onClick={onClickNavLink} className={navBarLink.bind(this, matchPath(location.pathname, `/${params.lang}/contact/`) !== null)} to='contact'>{params.lang === 'he' ? 'צרו קשר' : 'Contact'}</NavLink>
                <div className='text-6xl text-gray-400'>·</div>
                <LanguagePicker />
            </nav>
        </div>
        <Outlet />
        <Footer />
    </div>);
}

export default NavigationBar;