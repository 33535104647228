import * as React from 'react';
import mp4video from './pumpsuit.mp4'
import tablet from './tablet.jpg'
import UserGroupIcon from '@heroicons/react/24/solid/UserGroupIcon';
import EyeIcon from '@heroicons/react/24/solid/EyeIcon';
import CloudIcon from '@heroicons/react/24/outline/CloudIcon';
import ClockIcon from '@heroicons/react/24/outline/ClockIcon';
import StarIcon from '@heroicons/react/24/outline/StarIcon';
import HandThumbUpIcon from '@heroicons/react/24/outline/HandThumbUpIcon';
import ArrowUpIcon from '@heroicons/react/24/outline/ArrowUpIcon';
import pic1 from './IMG_0392.jpg';

const advantages_icon_classname = 'text-yellow-300 stroke-1 w-20 h-20';

const advantages2 = [
    { icon: <ClockIcon className={advantages_icon_classname} />, title: 'Create more content in less time' },
    { icon: <EyeIcon className={advantages_icon_classname} />, title: 'Overview additional gym information' },
    { icon: <CloudIcon className={advantages_icon_classname} />, title: 'Data preservation' },
    { icon: <UserGroupIcon className={advantages_icon_classname} />, title: 'Communication between the staff and your clients' },
    { icon: <HandThumbUpIcon className={advantages_icon_classname} />, title: 'Same gym workflow - but digital' },
    { icon: <StarIcon className={advantages_icon_classname} />, title: 'No third-party application needed' },
]

const advantageshe = [
    { icon: <ClockIcon className={advantages_icon_classname} />, title: 'יצירת תוכן מקצועי ביעילות' },
    { icon: <EyeIcon className={advantages_icon_classname} />, title: 'נקודת מבט נוספת על ביצועי המועדון' },
    { icon: <CloudIcon className={advantages_icon_classname} />, title: 'שימור מידע' },
    { icon: <UserGroupIcon className={advantages_icon_classname} />, title: 'תקשורת בין הצוות המקצועי והלקוחות' },
    { icon: <HandThumbUpIcon className={advantages_icon_classname} />, title: 'מותאם אל עבודה במכון כושר' },
    { icon: <StarIcon className={advantages_icon_classname} />, title: 'ללא צורך בהורדת אפליקציה' },
]

const HomeScreen = () => {

    return (<div>
        <div className='flex flex-1 pb-96 h-1/2'>
            <video playsInline autoPlay muted loop className="myVideo h-1/2" id='vid'>
                <source src={mp4video} type="video/mp4" />
            </video>
            <div className='absolute h-1/2 z-10 flex flex-1 w-full justify-center items-center text-4xl xl:text-7xl bg-opacity-50 bg-black text-white text-center'>
                <div className='font-roboto rtl:hidden'>The <span className=' text-transparent bg-clip-text bg-gradient-to-r from-blue-200 to-blue-400'>Connection</span> Between A Gym And It's Clients</div>
                <div className=' ltr:hidden'><span className=' text-transparent bg-clip-text bg-gradient-to-r from-blue-200 to-blue-400 font-sans-he font-extrabold'>
                    מחברים</span> את המנוי למועדון</div>
            </div>
        </div>
        <div className='text-center items-center justify-center text-6xl mt-20 xl:px-0 xl:mt-96 text-gray-500 font-thin space-y-5'>
            <div className='rtl:hidden'>
                <div>It's time for the flourishing Gym industry</div>
                <div>to expand into the digital world.</div>
            </div>
            <div className='ltr:hidden'>
                <div>מועדוני הכושר מתפתחים לעבר</div>
                <div>העולם הדיגיטלי.</div>
            </div>
            <div className='py-52 xl:py-40 w-screen items-center justify-center flex'>
                <div className='p-3 w-fit bg-gray-50 rounded-xl border border-gray-300 shadow-xl '>
                    <span className='font-semibold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-red-600'>
                        Suit
                    </span>
                    <span className='text-3xl'> by PumpApp</span>
                </div>
            </div>

            <div className='w-screen h-144' style={{backgroundImage: `url(${pic1})`, backgroundPosition: '50% 50%', backgroundSize: '100%'}} />

        </div>
        <div className='flex flex-col xl:flex-row justify-center xl:mt-20'>
            <div className='rtl:hidden mx-3 xl:mx-0 xl:basis-1/3 justify-center items-center'>
                <div className='text-7xl xl:tracking-wider font-bold font-roboto'>Our Vision</div>
                <div className='text-gray-600 text-3xl xl:text-4xl mt-5 xl:leading-normal xl:font-semibold flex-1 pr-10'>
                    PumpSuit was invented to give gyms the right professional management tool for delivering a personal workout experience to all its clients.
                    <br />
                    Our vision is to make the gym industry deliver their product to every customer, giving answer for client loss –
                    <span className='font-bold'> Making sure the customers get what they came for.</span>
                </div>
            </div>
            <div className='ltr:hidden mx-3 xl:mx-0 xl:basis-1/3 justify-center items-center'>
                <div className='text-7xl xl:tracking-wider font-bold font-sans-he'>החזון שלנו</div>
                <div className='text-gray-600 text-3xl xl:text-4xl mt-5 xl:leading-normal font-sans-he xl:font-semibold'>
                    PumpSuit היא מערכת ניהול מקצועי למועדוני כושר שיוצרת חוויית אימון אישי לכל מנויי המועדון.
                    <br />
                    החזון שלנו הוא לעזור למועדון לספק את המוצר שלו במלוא הפוטנציאל ולצמצם נטישת לקוחות -
                    <span className='font-bold'> לוודא שהלקוח מקבל את היחס שהוא צריך</span>
                </div>
            </div>
            <div className='xl:basis-1/3 xl:rounded-3xl bg-no-repeat w-full h-96 xl:w-auto xl:h-auto mt-10 xl:mx-0' style={{
                backgroundImage: `url(${tablet})`,
                backgroundPosition: '50%',
                backgroundSize: '165%',
            }}>
            </div>
        </div>
        <div className='rtl:hidden flex flex-1 flex-col bg-gray-800 p-40 mt-20 xl:mt-72 items-center justify-center relative'>
            <div className='text-7xl text-white text-center'>Our Benefits</div>
            <div className='flex mt-20 justify-between xl:w-4/6 flex-wrap'>
                {advantages2.map((advantage) => <div key={advantage.title} className='flex flex-col font-roboto space-y-4 items-center m-10 w-80'>
                    <div>{advantage.icon}</div>
                    <p className='text-4xl text-white text-center'>{advantage.title}</p>
                </div>)}
            </div>
            <div className='border cursor-pointer rounded-full w-16 h-16 p-4 bg-white absolute bottom-4 rtl:left-4 ltr:right-4 text-black' onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }}><ArrowUpIcon /></div>
        </div>
        <div className='ltr:hidden flex flex-1 flex-col bg-gray-800 p-20 mt-20 xl:mt-72 items-center justify-center relative'>
            <div className='text-7xl text-white text-center'>נקודות מרכזיות</div>
            <div className='flex mt-20 justify-between xl:w-4/6 flex-wrap'>
                {advantageshe.map((advantage) => <div key={advantage.title} className='flex flex-col space-y-4 items-center m-10 w-80'>
                    <div>{advantage.icon}</div>
                    <p className='text-4xl text-white text-center'>{advantage.title}</p>
                </div>)}
            </div>
            <div className='border cursor-pointer rounded-full w-16 h-16 p-4 bg-white absolute bottom-4 rtl:left-4 ltr:right-4 text-black' onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }}><ArrowUpIcon /></div>
        </div>
    </div>);
}

export default HomeScreen;