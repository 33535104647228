import EN from '../images/EN.svg';
import HE from '../images/HE.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const languages = [
    { name: 'English', icon: EN, path: '/en' },
    { name: 'עברית', icon: HE, path: '/he' },
]

const LanguagePicker = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="z-30 flex space-x-2 rtl:space-x-reverse items-center justify-center h-full" >
            {languages.map((lang) => (
                <div title={lang.name}  key={lang.path}
                    onClick={() => navigate(`${lang.path}/${location.pathname.split('/')[2] || ''}`)}
                    style={{
                        backgroundImage: `url(${lang.icon})`,
                        backgroundSize: `cover`
                    }}
                    className='cursor-pointer h-8 w-12 bg-no-repeat inline border-2 border-gray-200 rounded-md' />
            ))}
        </div>
    )
}

export default LanguagePicker;