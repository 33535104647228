import { lazy, Suspense } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import LoadingIndicator from './components/LoadingIndicator';
import NavigationBar from './components/NavigationBar';
import CompanyScreen from './screens/CompanyScreen';
import ContactScreen from './screens/ContactScreen';
import HomeScreen from './screens/HomeScreen';
import PlatformScreen from './screens/PlatformScreen';

function App() {
  const [cookies] = useCookies(['lang']);

  console.log(process.env)

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/:lang' element={<NavigationBar />}>
          <Route index element={<HomeScreen />} />
          <Route path='company' element={<CompanyScreen />} />
          <Route path='platform' element={<PlatformScreen />} />
          <Route path='contact' element={<ContactScreen />} />
        </Route>
        <Route path='*' element={<Navigate to={cookies.lang || 'en'} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
