import ArrowUpIcon from '@heroicons/react/24/outline/ArrowUpIcon';
import * as React from 'react';
import bg1 from './pexels-adrien-olichon-3767172.jpg'
import pic_yoav from './yoav.jpeg';
import pic_itamar from './itamar.jpeg';
import pic_netanel from './netanel.jpg';
import pic_eithan from './eithan.jpg';

const team = [
    { picture: pic_netanel, name: 'Netanel Amor', title: 'Co-Founder & CEO', description: 'Operations of company' },
    { picture: pic_yoav, name: 'Yoav Karpassi', title: 'Co-Founder & CTO', description: 'Software Developer' },
    { picture: pic_itamar, name: 'Itamar Cohen', title: 'Co-Founder & CMO', description: 'Marketing Activities' },
    { picture: pic_eithan, name: 'Eithan Haronian', title: 'Co-Founder & CIO', description: 'Software Developer' },

]

const teamhe = [
    { picture: pic_netanel, name: 'נתנאל עמור', title: 'מייסד & CEO', description: 'מנכ"ל החברה' },
    { picture: pic_yoav, name: 'יואב כרפסי', title: 'מייסד & CTO', description: 'פיתוח תוכנה' },
    { picture: pic_itamar, name: 'איתמר כהן', title: 'מייסד & CMO', description: 'שיווק ולקוחות' },
    { picture: pic_eithan, name: 'איתן הרוניאן', title: 'מייסד & CIO', description: 'פיתוח תוכנה' },

]

const CompanyScreen = () => {
    return (
        <div className='min-h-screen'>
            <div className='flex-1 py-40 w-screen bg-scroll xl:bg-fixed bg-no-repeat' style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className='rtl:hidden font-bold text-6xl xl:text-9xl font-roboto text-white mx-20'>Company</div>
                <div className='ltr:hidden text-6xl xl:text-9xl text-white mx-20'>חברה</div>
            </div>


            <div className='rtl:hidden flex-1 mt-40 mx-3 xl:mx-20 space-y-10'>
                <div className='font-bold text-4xl xl:text-8xl xl:font-roboto text-gray-600'>
                    About PumpApp
                </div>
                <div className='text-gray-600 text-2xl xl:text-5xl font-thin leading-normalplus xl:p-20 flex-1'>
                    PumpApp was founded by entrepreneurs with a mission to transform how wellness market organizations supply their product.<br />
                    By developing the common and needed tools, every business can improve their costumer’s experience.<br /><br />
                    Using the newest technology we aim to drive the wellness market to a higher success rate - <span className='font-bold'>helping businesses offer the full potential of their products.</span>
                </div>
            </div>

            <div className='ltr:hidden flex-1 mt-40 mx-3 xl:mx-20 space-y-10'>
                <div className='font-bold text-4xl xl:text-8xl xl:font-roboto text-gray-600'>
                    על PumpApp
                </div>
                <div className='text-gray-600 text-2xl xl:text-5xl font-thin leading-normalplus xl:p-20 flex-1'>
                    PumpApp נוסדה על ידי יזמים עם משימה לשנות את הדרך שבה ארגונים בתעשיית הבריאות והכושר מספקים את המוצר ללקוח.
                    <br />
                    אנו מפתחים מוצרים עם הטכנולוגיה המתקדמת בשוק לרווחת עסקים מעולם הבריאות והכושר על מנת שיגיעו לאחוזי הצלחה גדולים יותר -
                    <span className='font-bold'> לעזור לעסקים לממש את מלוא הפוטנציאל של המוצר אותו הם מספקים.</span>
                </div>
            </div>

            <div className='flex-1 mt-20 mx-3 xl:mx-20 space-y-10'>
                <div className='rtl:hidden font-bold text-4xl xl:text-8xl xl:font-roboto text-gray-600'>
                    Team
                </div>
                <div className='ltr:hidden font-bold text-4xl xl:text-8xl xl:font-roboto text-gray-600'>
                    הצוות
                </div>
            </div>

            <div className='rtl:hidden flex flex-row flex-1 flex-wrap justify-between p-2 xl:p-40 xl:space-x-10 relative'>
                {team.map((person) => (<div key={person.name} className='space-y-2 my-10 xl:mb-0 items-center lg:items-start flex flex-col flex-1 '>
                    <div style={{ backgroundImage: `url(${person.picture})`, backgroundSize: 'cover' }} className='h-80 w-64 xl:h-96 xl:w-80 rounded-3xl bg-no-repeat flex flex-col' />
                    <div className='font-roboto text-gray-600 text-3xl xl:text-4xl'>{person.name}</div>
                    <div className='text-gray-600 text-3xl xl:text-3xl font-thin' >{person.title}</div>
                    <div className='text-gray-600 text-3xl xl:text-4xl font-thin' >{person.description}</div>
                </div>))}
                <div className='cursor-pointer rounded-full w-16 h-16 p-4 bg-white absolute bottom-4 rtl:left-4 ltr:right-4 text-black border' onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }}><ArrowUpIcon /></div>
            </div>
            <div className='ltr:hidden flex flex-row flex-1 flex-wrap justify-between p-2 xl:p-40 xl:space-x-10 rtl:space-x-reverse relative'>
                {teamhe.map((person) => (<div key={person.name} className='space-y-2 my-10 xl:mb-0 items-center lg:items-start flex flex-col flex-1 '>
                    <div style={{ backgroundImage: `url(${person.picture})`, backgroundSize: 'cover' }} className='h-80 w-64 xl:h-96 xl:w-80 rounded-3xl bg-no-repeat flex flex-col' />
                    <div className='text-gray-600 text-3xl xl:text-4xl'>{person.name}</div>
                    <div className='text-gray-600 text-3xl xl:text-3xl font-thin' >{person.title}</div>
                    <div className='text-gray-600 text-3xl xl:text-4xl font-thin' >{person.description}</div>
                </div>))}
                <div className='cursor-pointer rounded-full w-16 h-16 p-4 bg-white absolute bottom-4 rtl:left-4 ltr:right-4 text-black border' onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }}><ArrowUpIcon /></div>
            </div>
        </div>);
}

export default CompanyScreen;