import * as React from 'react';
import kiosk_face from './IMG_E0441.jpg';
import phone_hand from './hand.png'

import img_1 from './userWithPdfPlan.jpg'
import img_2 from './2.jpg'
import img_3 from './IMG_0443.jpg'
import CheckIcon from '@heroicons/react/24/outline/CheckIcon';
import ArrowUpIcon from '@heroicons/react/24/outline/ArrowUpIcon';

const letsYou = [
    'Manage and track clients workout plan, measurements and personal goals.',
    'A way for clients to view their personal workout plan with instructions and videos to each exercise.',
    'Be aware of your clients need with a P.O Box for rating the facility, machines and trainers.',
    'Collect workout plans for trainers to expand and share their professional knowledge.',
    'Know your gym performance with a monthly report.'
];

const letsYouHe = [
    'לנהל ולעקוב אחר תוכניות האימון, המדדים, ומטרות אישיות של המנוי.',
    'דרך למנויים לצפות בתכניות האימון האישיות עם סירטוני הדרכה ללא אפליקציה בנייד.',
    'מודעות לצרכי הלקוח ע"י תיבת פניות ודירוגים למתחם, למכשירים ולמאמנים.',
    'ספריית אימונים לשיתוף והרחבת הידע המקצועי עבור המאמנים.',
    'דוחות חודשיים על ביצועי המכון.'
]

const PlatformScreen = () => {

    const [width, setWidth] = React.useState<number>(0);

    const handleResize = () => {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return window.removeEventListener('resize', handleResize);
    }, [])



    return (<div className='min-h-screen flex-1 flex flex-col'>
        <div className='flex h-96 xl:h-144 items-center  bg-no-repeat' style={{
            backgroundImage: `url(${kiosk_face})`,
            backgroundPosition: 'left',
            backgroundSize: 'cover'
        }}>
            <div className='rtl:hidden text-white font-roboto text-4xl xl:text-8xl mx-5 xl:mx-20 mt-72'>PumpSuit System</div>
            <div className='ltr:hidden text-black font-bold text-4xl xl:text-8xl mx-5 xl:mx-96 mt-72 font-sans-he'>מערכת PumpSuit</div>

        </div>

        <div className='rtl:hidden mt-40 xl:mx-20 text-4xl xl:text-7xl flex flex-col leading-snug px-5 xl:px-24'>
            <span className='font-bold text-gray-800 text-left xl:px-64'>PumpSuit handles all workout content created by the trainer and trainee</span>
            <span className='font-normal text-gray-700 font-sans text-3xl xl:text-5xl xl:leading-normalplus mt-5 xl:px-64 font-roboto'>
                Integrative system that adapts to the already existing gym workflow.<br />
                Create clients professional file in a way the gym performance and the clients progress are being managed.<br className='' />
                <span className='font-bold font-roboto'>From a fully explained workout plan - to a gym activity monthly report.</span>
            </span>
        </div>

        <div className='ltr:hidden mt-40 xl:mx-20 text-4xl xl:text-7xl flex flex-col leading-snug px-5 xl:px-24  font-sans-he'>
            <span className='font-semibold text-gray-800 xl:px-64'>PumpSuit מנהלת את התוכן המקצועי הנוצר על ידי המועדון, המאמן והמנוי</span>
            <span className='font-normal text-gray-700 font-sans text-3xl xl:text-5xl xl:leading-normalplus mt-5 xl:px-64'>
                PumpSuit היא מערכת שיתופית שמתלבשת על תהליכי עבודה קיימים במכון,<br />
                יוצרת תיק מקצועי עבור המנויים ומאפשרת מעקב אחר ביצועי המכון והתקדמות המנויים.<br /><br />
                <span className='font-semibold'>מיצירה של תוכנית אימון - לדוחות מכון חודשיים</span>
            </span>
        </div>

        <div dir='ltr' className='flex flex-row justify-center mt-20 xl:mt-96 w-screen h-64 xl:h-160'>
            <div style={{ backgroundImage: `url(${img_1})`, backgroundPosition: 'bottom', backgroundSize: width < 640 ? '200%' : '200%', backgroundRepeat: 'no-repeat' }}
                className='basis-1/3 h-52 xl:h-auto xl:basis-1/4 ltr:rounded-l-xl'>
            </div>
            <div style={{ backgroundImage: `url(${img_2})`, backgroundPosition: '58% top', backgroundSize: width < 640 ? '150%' : '100%', backgroundRepeat: 'no-repeat' }}
                className='basis-1/3 h-52 xl:h-auto xl:basis-1/4'>
            </div>
            <div style={{ backgroundImage: `url(${img_3})`, backgroundPosition: 'center', backgroundSize: width < 640 ? '180%' : 'contain', backgroundRepeat: 'no-repeat' }}
                className='basis-1/3 h-52 xl:h-auto xl:basis-1/4 ltr:rounded-r-xl'>
            </div>
        </div>

        <div className='flex flex-col xl:flex-row justify-center mt-20 xl:mt-96'>
            <div className='rtl:hidden px-2 xl:px-0 xl:basis-1/3 justify-center items-center'>
                <div className='text-4xl xl:text-6xl tracking-wider font-semibold'>PumpSuit lets you</div>
                <div className='text-gray-600 text-3xl lg:text-4xl mt-5 xl:leading-normal xl:font-semibold flex-row space-y-3'>
                    {letsYou.map((text) => (
                        <div className='flex flex-row'>
                            <div className='text-green-500'>
                                <CheckIcon className='w-10 xl:mt-2 mx-4' />
                            </div>
                            <div>
                                {text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='ltr:hidden px-2 xl:px-0 xl:basis-1/3 justify-center items-center font-sans-he'>
                <div className='text-4xl xl:text-6xl tracking-wider font-bold'>PumpSuit נותנת לך</div>
                <div className='text-gray-600 text-3xl lg:text-4xl mt-10 xl:leading-normal xl:font-semibold space-y-3'>
                    {letsYouHe.map((text) => (
                        <div className='flex flex-row'>
                            <div className='text-green-500'>
                                <CheckIcon className='w-10 mx-4' />
                            </div>
                            <div>
                                {text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='h-96 xl:h-auto w-96 xl:w-auto xl:basis-1/3 rounded-3xl bg-no-repeat' style={{
                backgroundImage: `url(${phone_hand})`,
                backgroundPosition: 'center 40%',
                backgroundSize: '200%'
            }}>
            </div>
        </div>
        <div className='items-center justify-end flex flex-1 p-3'>
            <div className='cursor-pointer rounded-full w-16 h-16 p-4 bg-white bottom-4 text-black border' onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }}><ArrowUpIcon /></div>
        </div>
        <div className='mt-10' />
    </div>);
}

export default PlatformScreen;