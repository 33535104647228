import { createRef, useEffect, useState } from "react";
import emailjs from '@emailjs/browser';
import { useParams } from "react-router-dom";

const enMessages = [
    'Your request has been sent, we will back to you soon.',
    'Request failed',
    'Invalid name or phone number'
]

const heMessages = [
    'הבקשה נשלחה, נחזור אליכם בהקדם!',
    'הבקשה לא נשלחה עקב תקלה',
    'שם או מספר טלפון שגויים'
]

const ContactScreen = () => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const form = createRef<HTMLFormElement>();
    const formHe = createRef<HTMLFormElement>();

    const params = useParams();

    useEffect(() => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setErrorMessage('');
        setSuccessMessage('');
        setLoading(false);
    },[params.lang])

    async function sendEmail(lang: 'he' | 'en' = 'en') {
        let messages = lang === 'en' ? enMessages : heMessages;
        if (name.length > 1 && phoneNumber.length >= 10) {
            setLoading(true);
            let message = await emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_EMAILJS_TEMPLATE_ID!, lang === 'en' ? form.current as HTMLFormElement : formHe.current as HTMLFormElement, process.env.REACT_APP_EMAILJS_PUBLIC_TOKEN!)

            if (message.text === 'OK') {
                setSuccessMessage(messages[0]);
                setErrorMessage('');
            }
            else {
                setSuccessMessage('');
                setSuccessMessage(messages[1]);
            }

        }
        else {
            setSuccessMessage('');
            setErrorMessage(messages[2]);
        }
        setLoading(false);
    }


    return (<div className="bg-gray-50 min-h-screen p-4 py-10 xl:p-20">
        <div className="rtl:hidden flex flex-col items-start">
            <div className="text-4xl text-blue-500 font-bold mb-10">Request a demo</div>
            <div className="text-3xl mx-5">If you would like to request a demo for your own gym, let us know!</div>
            <form ref={form} className={`mx-5 mt-5 border p-5 ${errorMessage !== '' && 'border-red-500'}`}>
                <table className="text-left font-thin font-sans" cellPadding={10}>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">Full name</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full disabled:border-0" name='user_name' placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} /></td>
                    </tr>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">Email</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full" name='user_email' placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                    </tr>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">Phone number</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full" name='user_phone' placeholder="Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="hidden xl:block"></td>
                        {!successMessage && <td>
                            {!loading &&<button className="border w-full hover:bg-blue-500 hover:text-white bg-white p-2 font-roboto text-xl" type='button' onClick={async () => { await sendEmail() }}>SUBMIT</button>}
                        </td>}
                    </tr>
                </table>
                {successMessage && <div id='txtSent' className="text-3xl w-fit text-white bg-green-500 p-2 rounded-md">{successMessage}</div>}
                {errorMessage && <div id='txtError' className="text-3xl w-fit bg-red-500 text-white rounded-md p-2">{errorMessage}</div>}
            </form>
            <div className="text-3xl xl:text-4xl mt-20 text-blue-500 font-bold mb-10">Email</div>
            <div className="text-2xl xl:text-3xl mx-5"><a className='underline underline-offset-5 hover:text-blue-500 hover:no-underline' href='mailto:support@pumpapp.app'>support@pumpapp.app</a></div>
        </div>
        <div className="ltr:hidden flex flex-col items-start">
            <div className="text-4xl text-blue-500 font-bold mb-10">חודש ניסיון</div>
            <div className="text-3xl mx-5">אם אתם מעוניינים לקבל חודש ניסיון עם המערכת שלנו, תנו לנו לדעת!</div>
            <form ref={formHe} className={`mx-5 mt-5 border p-5 w-max ${errorMessage !== '' && 'border-red-500'}`}>
                <table className="text-right font-thin font-sans" cellPadding={10}>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">שם מלא</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full" name='user_name' placeholder="שם מלא" value={name} onChange={(e) => setName(e.target.value)} /></td>
                    </tr>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">אימייל</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full" name='user_email' placeholder="אימייל" value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                    </tr>
                    <tr className="text-3xl font-normal">
                        <td className="hidden xl:block">טלפון</td>
                        <td><input disabled={successMessage !== ''} className="border text-2xl p-2 w-full" name='user_phone' placeholder="טלפון" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="hidden xl:block">
                        </td>
                        {!successMessage && <td>
                            {!loading && <button className="border w-full hover:bg-blue-500 hover:text-white bg-white p-2 font-sans-he text-xl" type='button' onClick={async () => { await sendEmail('he') }}>שליחה</button>}
                        </td>}
                    </tr>
                </table>
                {successMessage && <div id='txtSent' className="text-2xl text-white bg-green-500 p-3 rounded-md font-sans-he w-fit">{successMessage}</div>}
                {errorMessage && <div id='txtError' className="text-2xl bg-red-500 text-white rounded-md p-3 font-sans-he w-fit">{errorMessage}</div>}
            </form>

            <div className="text-3xl xl:text-4xl mt-20 text-blue-500 font-bold mb-10">אימייל</div>
            <div className="text-2xl xl:text-3xl mx-5"><a className='underline underline-offset-5 hover:text-blue-500 hover:no-underline' href='mailto:support@pumpapp.app'>support@pumpapp.app</a></div>
        </div>

    </div>);
}

export default ContactScreen;