import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="py-5 px-5 xl:py-16 xl:px-16 text-xl xl:text-2xl space-y-2 text-center">
            <div className="rtl:hidden">
                © Copyrights 2022 PumpApp Inc \ פאמפאפ בע"מ
            </div>
            <div className="ltr:hidden">
                © כל הזכויות שמורות 2022 PumpApp Inc \ פאמפאפ בע"מ
            </div>
            <div className="rtl:hidden">
                <Link to='contact' className=" hover:text-red-500 font-bold">Request a demo</Link>
            </div>
            <div className="ltr:hidden">
                <Link to='contact' className=" hover:text-red-500 font-bold">חודש ניסיון</Link>
            </div>
        </div>
    );
}

export default Footer